import axios from './fetch'

axios.defaults.headers.common['Authorization'] =  "Bearer "+localStorage.getItem('token');
export default async ({
    url,
    method,
    isTh,
    filename,
    data
}) =>{
    switch (isTh) {
        case 1:
            await axios({
                url:url,
                method:method,
                data:data,
                Headers:{}
            }).then((res)=>{
                var fileLink = document.createElement('a');
                fileLink.href='rawbt:'+res.data
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink)
                return true
            }).catch((err)=>{
                throw err
            })
            break;
        default:
            await axios({
                url:url,
                method:method,
                responseType:'blob',
                data:data,
                Headers:{}
            }).then((res)=>{
                var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download',filename);
                document.body.appendChild(fileLink);
                return fileLink.click();
            }).catch((err)=>{
                throw err
            })
            break;
    }
}